module.exports = {
	"rule_segments": {
		"title": "Rules",
		"help": "Create root segment and add segment or action to it. Rules are executed from top to bottom.",
		"create": "Create rule segment",
		"update": "Edit segment",
		"move_up": "Move one level up",
		"move_down": "Move one level down",
		"edit": "Edit",
		"delete_warning": "Element and its contents will be deleted",
		"create_success": "Segment successfully created",
		"update_success": "Segment successfully updated",
		"delete_success": "Segment successfully deleted",
		"conditions_mode": "Conditions mode",
		"conditions_type": "Type",
		"conditions_operator": "Operator",
		"conditions_operator_values": {
			"contains": "contains",
			"in_range": "in range",
			"intersect": "intersect",
			"not_contains": "not contains",
			"not_intersect": "not intersect"
		},
		"conditions_value": "Value",
		"add_condition": "Add condition",
		"duplicate": "Duplicate",
		"segment": "Segment",
		"action": "Action",
		"strict_search": "Strict search",
		"conditions": {
			"types": {
				"vwo_variations": "VWO variations",
				"card_brand": "Card brand",
				"card_country": "Card country",
				"card_issuer": "Card issuer",
				"customer_account_lifetime_days": "Customer account lifetime in days",
				"customer_balance": "Customer balance",
				"customer_country": "Customer country",
				"customer_currency": "Customer currency",
				"customer_deposit_count": "Customer deposits count",
				"customer_deposit_sum": "Customer deposit sum",
				"customer_game_count": "Customer game count",
				"customer_spin_count": "Customer spin count",
				"customer_loyal": "Customer loyal",
				"customer_vip": "Customer VIP",
				"customer_potential_payments_count": "Customer potential payments count",
				"customer_suspicions": "Customer suspicions",
				"customer_type": "Customer type",
				"customer_withdraw_count": "Customer withdrawals count",
				"customer_withdraw_sum": "Customer withdrawals sum",
				"customer_verified": "Customer verified",
				"customer_deposit_status": "Customer deposit status",
				"ip_country": "Country by IP",
				"jurisdiction": "Jurisdiction",
				"apple_pay_available": "Apple Pay available",
				"google_pay_available": "Google Pay available",
				"locale": "Locale",
				"payment_type": "Payment type",
				"processor": "Processor",
				"processor_currency": "Processor currency",
				"project": "Project",
				"system": "System",
				"widget_mode": "Widget mode",
				"widget_submode": "Widget submode (opening page)",
				"current_time": "Current time",
				"customer_or_ip_country": "Customer or IP country",
				"customer_email": "Customer email",
				"customer_group_number": "Customer group number",
				"processor_account": "Processor account",
				"withdraw_amount": "Withdraw amount",
				"subsystem_name": "Subsystem"
			},
			"labels": {
				"since_ago": "Since ago",
				"count": "Count",
				"potential_payments_period": "Period",
				"processors": "Processors",
				"systems": "Systems",
				"since_ago_tooltip": "Examples of parse-able strings:\n'12.4 secs'\n'1:20'\n'1:20.51'\n'4:01:01'\n'3 mins 4 sec'\n'2 hrs 20 min'\n'2h20min'\n'6 mos 1 day'\n'47 yrs 6 mos and 4d'\n'two hours and twenty minutes'\n'3 weeks and 2 days'\n"
			},
			"modes": {
				"and": "AND",
				"or": "OR"
			}
		},
		"potential_payments": {
			"periods": {
				"day": "Day",
				"week": "Week",
				"month": "Month"
			}
		},
		"list": {
			"potential_payments": "{subject} {paymentType} per {freq}",
			"modifiers": {
				"payment_period": "{subject} for last {period}",
				"payment_period_processors": "{subject} for last {period} through {processors} processors",
				"payment_period_systems": "{subject} for last {period} through {systems} systems",
				"payment_processors": "{subject} through {processors} processors",
				"payment_processors_systems": "{subject} through {processors} processors and {systems} systems",
				"payment_systems": "{subject} through {systems} systems",
				"payment_all": "{subject} for last {period} through {processors} processors and {systems} systems"
			}
		}
	}
};