module.exports = {
	"rule_segments": {
		"title": "Правила",
		"help": "Создайте сегмент верхнего уровня и добавьте к нему действие или другой сегмент. Правила выполняются сверху-вниз.",
		"create": "Новый сегмент",
		"update": "Редактирование сегмента",
		"move_up": "Перенести на один уровень вверх",
		"move_down": "Перенести на один уровень вниз",
		"edit": "Редактировать",
		"delete_warning": "Элемент и его содержимое будут удалены",
		"create_success": "Сегмент успешно создан",
		"update_success": "Сегмент успешно обновлен",
		"delete_success": "Сегмент успешно удален",
		"conditions_mode": "Связь",
		"conditions_type": "Условие",
		"conditions_operator": "Оператор",
		"conditions_operator_values": {
			"contains": "содержит",
			"in_range": "в диапазоне",
			"intersect": "пересекает",
			"not_contains": "не содержит",
			"not_intersect": "не пересекает"
		},
		"conditions_value": "Значение",
		"add_condition": "Добавить условие",
		"duplicate": "Дублировать",
		"segment": "Сегмент",
		"action": "Действие",
		"strict_search": "Строгий поиск",
		"conditions": {
			"types": {
				"vwo_variations": "VWO вариации",
				"card_brand": "Брэнд карты",
				"card_country": "Страна карты",
				"card_issuer": "Эмитент карты",
				"customer_account_lifetime_days": "Время жизни аккаунта игрока в днях",
				"customer_balance": "Баланс игрока",
				"customer_country": "Страна игрока",
				"customer_currency": "Валюта игрока",
				"customer_deposit_count": "Количество депозитов игрока",
				"customer_game_count": "Количество игр игрока",
				"customer_spin_count": "Количество спинов игрока",
				"customer_deposit_sum": "Сумма депозитов игрока",
				"customer_loyal": "Лояльный игрок",
				"customer_vip": "VIP игрок",
				"customer_potential_payments_count": "Число потенциальных платежей",
				"customer_suspicions": "Подозрения игрока",
				"customer_type": "Тип игрока",
				"customer_withdraw_count": "Количество выплат игрока",
				"customer_withdraw_sum": "Сумма выплат игрока",
				"customer_verified": "Игрок верифицирован",
				"customer_deposit_status": "Депозитный статус игрока",
				"ip_country": "Страна по IP",
				"jurisdiction": "Юрисдикция",
				"apple_pay_available": "Доступен Apple Pay",
				"google_pay_available": "Доступен Google Pay",
				"locale": "Локаль",
				"payment_type": "Тип платежа",
				"processor": "Процессинг",
				"processor_currency": "Валюта процессинга",
				"project": "Проект",
				"system": "Система",
				"widget_mode": "Вид виджета",
				"widget_submode": "Подвид виджета (страница открытия)",
				"current_time": "Текущее время",
				"customer_or_ip_country": "Страна игрока или по IP",
				"customer_email": "Email игрока",
				"customer_group_number": "Номер группы игрока",
				"processor_account": "Аккаунт процессинга",
				"withdraw_amount": "Сумма выплаты",
				"subsystem_name": "Подсистема"
			},
			"labels": {
				"since_ago": "За последние",
				"count": "Число",
				"potential_payments_period": "Период",
				"processors": "Процессинги",
				"systems": "Системы",
				"since_ago_tooltip": "Примеры корректных значений:\n'12.4 secs'\n'1:20'\n'1:20.51'\n'4:01:01'\n'3 mins 4 sec'\n'2 hrs 20 min'\n'2h20min'\n'6 mos 1 day'\n'47 yrs 6 mos and 4d'\n'two hours and twenty minutes'\n'3 weeks and 2 days'\n"
			},
			"modes": {
				"and": "И",
				"or": "ИЛИ"
			}
		},
		"potential_payments": {
			"periods": {
				"day": "День",
				"week": "Неделя",
				"month": "Месяц"
			}
		},
		"list": {
			"potential_payments": "{subject} {paymentType} за {freq}",
			"modifiers": {
				"payment_period": "{subject} за последние {period}",
				"payment_period_processors": "{subject} за последние {period} через процессинги {processors}",
				"payment_period_systems": "{subject} за последние {period} через системы {systems}",
				"payment_processors": "{subject} через процессинги {processors}",
				"payment_processors_systems": "{subject} через процессинги {processors} и системы {systems}",
				"payment_systems": "{subject} через системы {systems}",
				"payment_all": "{subject} за последние {period} через процессинги {processors} и системы {systems}"
			}
		}
	}
};