import makeStore from "$utils/stores/DataStore"
import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
  clearTokens,
} from "$utils/token"

const store = makeStore({
  accessToken: getAccessToken(),
  refreshToken: getRefreshToken(),
  authorized: false,
  email: null,
  projects: [],
  abilities: [],
  jurisdictions: [],
  currentTime: null,
  orderRejectionReasons: [],
  orderFullTypes: {},
  orderFailureSubtypes: {},
  systemTypes: [],
  allowOrderCreation: false,
}, {
  save (data) {
    data.jurisdictions?.sort()
    this.merge(data)
  },
})

store.subscribe(state => {
  if (state.accessToken && state.refreshToken) {
    setAccessToken(state.accessToken)
    setRefreshToken(state.refreshToken)
  } else {
    clearTokens()
  }
})

export default store
