module.exports = {
	"main": {
		"ab_tests": {
			"status": "Status",
			"title": "AB-tests",
			"name": "Name",
			"key": "Key",
			"jurisdictions": "Jurisdictions",
			"project_names": "Projects",
			"platforms": "Platforms",
			"variations": "Variations",
			"created_at": "Created at",
			"updated_at": "Updated at"
		},
		"payout_timings": {
			"title": "Payout timings",
			"project": "Project",
			"jurisdictions": "Jurisdictions",
			"deposit_status": "Deposit status",
			"danger_status": "Danger status",
			"interval": "Interval, minutes"
		},
		"balance_recommendations": {
			"title": "Balance recommendations",
			"name": "Balance",
			"amount": "Recommended amount"
		},
		"group_title": "Main",
		"courses": {
			"title": "Currencies",
			"course_in": "Exchange rate in",
			"course_for": "Exchange rate for"
		},
		"withdraw_queue": {
			"title": "Orders: payout queue",
			"player": "Player",
			"balance": "Balance",
			"unprocessed_orders": "Unprocessed orders",
			"orders_have_comments": "Have order comments",
			"verification": "Verification"
		},
		"turnover": {
			"title": "Player turnover"
		},
		"purses": {
			"title": "Purses",
			"processor": "Processor",
			"system": "System",
			"account": "Account",
			"bank": "Bank",
			"last_deposit_at": "Last successful deposit date",
			"last_withdraw_at": "Last successful payout date",
			"expires_at": "Expire date",
			"enabled": "Enabled",
			"deleted_at": "Deletion date",
			"banned": "Banned"
		},
		"hung_withdraws": {
			"title": "Orders: Hung withdraws",
			"short_title": "Hung withdraws"
		},
		"processor_fees": {
			"title": "Processor fees"
		},
		"logs": {
			"title": "User logs",
			"short_title": "Logs"
		},
		"csp": {
			"title": "CSP Rules"
		},
		"comments": {
			"empty": "No comments",
			"type": {
				"support": "Support",
				"system": "System",
				"payout_bot": "Payout bot"
			}
		},
		"banks": {
			"title": "Banks",
			"bank": "Bank",
			"aliases": "Aliases",
			"branding_id": "Branding ID",
			"actions": "Actions"
		},
		"order_failure_codes": {
			"title": "Order failure codes"
		},
		"custom_rates": {
			"title": "Custom rates"
		}
	}
};