module.exports = {
	"rule_actions": {
		"types_title": "Action types",
		"create_success": "Action successfully created",
		"update_success": "Action successfully updated",
		"delete_success": "Action successfully deleted",
		"action": "Action",
		"create": "Create action",
		"update": "Edit action",
		"priority": "Priority",
		"action_type": "Action type",
		"add_currency": "Add currency",
		"add_place": "Add place",
		"types": {
			"use_account": "Use account",
			"use_icon": "Use icon",
			"mark_as_p2p": "Mark as P2P",
			"use_processing_time_hint": "Use processing time hint",
			"disable_payments": "Disable payments",
			"use_alternative_contacts": "Use alternative contact",
			"set_deposit_limits": "Set deposit limit",
			"set_withdraw_limits": "Set withdraw limit",
			"set_autopay_limit": "Set autopay limit",
			"use_customer_fee": "Use customer fee",
			"set_deposit_default_amount_strategy": "Set deposit default amount strategy",
			"deny_payment_with_error": "Deny payment with error",
			"hide_system": "Hide system",
			"set_default_deposit_amount": "Set default deposit amount",
			"set_default_withdraw_amount": "Set default withdraw amount",
			"set_deposit_timeout": "Set deposit timeout",
			"set_method_priority": "Set PS priority",
			"make_method_preselected": "Make PS preselected",
			"set_amount_multiplicity": "Set amount multiplicity",
			"use_vwo_variation": "Use VWO variation",
			"set_order_description_template": "Set order description template",
			"mark_problem_channels": "Mark problem channel",
			"show_notification": "Show notification",
			"use_project_descriptor": "Use project descriptor",
			"set_dashboard_comment": "Dashboard comment",
			"set_alt_priority": "Set alt priority",
			"limit_deposits_sum": "Limit deposits sum",
			"block_payments": "Block payments",
			"set_min_rating": "Set min system rating"
		},
		"arguments": {
			"processor_account": "Account",
			"alt_priority": "Alt priority",
			"icon_name": "Icon name",
			"avg_seconds": "Avg time in sec.",
			"max_seconds": "Max time in sec.",
			"from_amount": "From",
			"to_amount": "To",
			"customer_fee_fix_amount": "Fixed amount",
			"customer_fee_percentage": "Percentage",
			"customer_fee_percentage_exceeding": "Percentage for amount exceeding",
			"customer_fee_max_amount": "Max fee amount",
			"customer_fee_show": "Show fee in widget",
			"deposit_default_amount_strategy": "Deposit default amount strategy",
			"error_code": "Error code",
			"amount": "Amount",
			"system_priority": "PS priority",
			"system_priority_hint": "The higher the value, the higher the priority. The default is 999",
			"multiplicity_amount": "Multiplicity amount",
			"vwo_experiment": "Experiment",
			"vwo_variation": "Variation",
			"order_description_template": "Order description template",
			"order_description_template_hint": "Supported placeholders: {{{project_name}}}, {{{external_id}}}, {{{payment_type}}}",
			"currency_limit_mapping": "Limits for currencies",
			"currency_limit_mapping_hint": "Limits for currency is taken. If there is no currency in mapping then first limits will be taken.",
			"currency_single_limit_mapping": "Limits for currencies",
			"chronic_duration": "Duration",
			"project_descriptor": "Project descriptor",
			"notification": "Code",
			"notification_screen": "Screen",
			"notification_system": "System (optional)",
			"comment": "Comment",
			"processor_names": "Processor names",
			"system_names": "System names",
			"period": "Period",
			"reason": "Reason",
			"system_rating": "PS rating"
		},
		"list": {
			"customer_fee": {
				"exceeding": "for amount exceeding",
				"max_amount": "max amount",
				"show": "show in widget",
				"dont_show": "don't show in widget"
			},
			"deposit_default_amount_strategy": {
				"last_deposit": "last deposit",
				"average": "average",
				"rule_or_rating": "rule or rating",
				"max_of_last_deposit_and_average": "max of last deposit and average"
			},
			"error_code": "Error code",
			"alt_priority": "alt priority",
			"modifiers": {
				"payment_period": "to {subject}, period — {period}",
				"payment_period_processors": "to {subject} through {processors} processors, period — {period}",
				"payment_period_systems": "to {subject} through {systems} systems, period — {period}",
				"payment_all": "to {subject} through {processors} processors and {systems} systems, period — {period}"
			}
		},
		"validation": {
			"code_screen_required": "Code and screen fields are required"
		}
	}
};