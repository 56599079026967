module.exports = {
	"errors": {
		"balances": {
			"wrong_jurisdiction": "Wrong jurisdiction",
			"missing_date": "Missing date",
			"bad_amount": "Amount must be greater than 0"
		},
		"banks": {
			"not_found": "Bank not found",
			"title_presence": "You must specify the title of bank",
			"uniqueness": "This bank title has been already taken",
			"try_later": "Try later"
		},
		"common": {
			"access_failed": "Access failed",
			"project_not_found": "Project not found",
			"invalid_url": "Invalid URL",
			"must_one_jurisdiction": "You must select at least one jurisdiction",
			"must_one_project": "You must select at least one project",
			"invalid_jurisdiction": "Invalid jurisdiction",
			"invalid_deposit_limits": "Invalid deposit limits",
			"invalid_withdraw_limits": "Invalid withdraw limits"
		},
		"csp": {
			"not_found": "Rule not found",
			"invalid_params": "Params are invalid"
		},
		"customers": {
			"not_found": "Customer not found",
			"no_token": "Can't open widget (customer has no token)"
		},
		"invalid_token": "Access failed",
		"order_comments": {
			"text_presence": "Comment text is required"
		},
		"orders": {
			"bank_card": "Bank card not confirmed",
			"banned_customer": "Banned customer",
			"daily_limit": "Daily payout limit exceeded, amount: {excess}",
			"incorrect_action": "Incorrect action",
			"incorrect_reject_reason": "Incorrect reject reason",
			"incorrect_status": "Order has incorrect status",
			"incorrect_time": "Incorrect time of payout",
			"many_results": "Founded more than one order",
			"monthly_limit": "Monthly payout limit exceeded, amount: {excess}",
			"not_found": "Order not found",
			"support_status": "Order marked by support as unpaid",
			"suspicious_customer": "Suspicious customer",
			"test_customer": "Test customer",
			"update_status": "Incorrect status, please, try to refresh the page",
			"weekly_limit": "Weekly payout limit exceeded, amount: {excess}",
			"not_refundable": "Impossible to refund",
			"refund_amount_invalid": "Invalid refund amount"
		},
		"processors": {
			"not_found": "Processor not found",
			"comment_presence_if_disabled": "Comment presence if processor disable"
		},
		"projects": {
			"already_exists": "Project with this title already exists",
			"api_key_presence": "You must specify the API key of the project",
			"name_presence": "You must specify the name of the project",
			"not_found": "Project not found",
			"not_unique_id": "The site ID is not unique",
			"not_valid_settings": "Invalid settings for {project}",
			"shortname_presence": "You must specify the shortname of the project",
			"table_sync_id_exists": "Project with this table_sync_id already exists",
			"title_presence": "You must specify the title of the project",
			"wrong_api_endpoint": "Invalid format of API address",
			"wrong_homepage_url": "Invalid format of homepage URL",
			"wrong_template_format": "Invalid format of link template"
		},
		"reports": {
			"too_many_results": "Too many results, try to reduce the period and / or the number of groupings",
			"invalid_period": "Period can not be more than 31 days"
		},
		"system_settings": {
			"deposits_unsupported": "The system does not support deposits",
			"incorrect_locale": "Incorrect locale",
			"not_found": "System not found",
			"withdraws_unsupported": "The system does not support withdraws"
		},
		"systems": {
			"not_found": "System not found",
			"incorrect_type": "Incorrect type",
			"incorrect_deposit_timeout": "Deposit timeout must be bigger than 0",
			"comment_presence_if_disabled": "Comment presence if system disable"
		},
		"users": {
			"not_found": "User not found"
		},
		"payout_bot": {
			"daily_limit": "Daily payout limit exceeded, amount: {excess}",
			"weekly_limit": "Weekly payout limit exceeded, amount: {excess}",
			"monthly_limit": "Monthly payout limit exceeded, amount: {excess}",
			"customer_autopay_disabled": "Autopay is disabled for customer",
			"project_autopay_disabled": "Autopay is disabled for project",
			"internal": "Payments for internal customers are prohibited",
			"suspicious_customer": "Suspicious customer",
			"documents_not_approved": "Documents not approved",
			"bank_card": "Bank card not approved",
			"no_deposits": "No deposits from purse",
			"low_balance": "Low balance",
			"test_customer": "Test customer",
			"banned_customer": "Banned customer",
			"support_status": "Order marked with support as unpaid",
			"nemesis_not_verified": "The order not verified in Nemesis",
			"autopay_disabled": "Order in manual process",
			"autopay_limits": "Daily autopay limit is exceeded",
			"autopay_amount": "Order amount is greater than autopay limit"
		},
		"alerts": {
			"not_found": "Alert not found",
			"invalid_until": "Invalid date"
		},
		"test_widget": {
			"one_click_unavailable": "1-click unavailable"
		},
		"processor_callbacks": {
			"period_too_large": "Period is too large (max {maxDays} days)",
			"invalid_content_type": "Invalid content_type",
			"processor_not_found": "Processor not found",
			"invalid_callback": "Invalid callback: {message}",
			"not_found": "Callback not found",
			"invalid_status": "Can't retry callback because it has invalid status"
		},
		"order_failure_codes": {
			"non_uniq_record": "This code already exists for the current processing"
		},
		"processor_accounts": {
			"invalid_account": "Invalid account",
			"invalid_yaml": "Invalid YAML. Line: {line}, column: {column}",
			"invalid_title": "Invalid title",
			"invalid_processor_name": "Chosen processor has no accounts",
			"invalid_jurisdictions": "Invalid jurisdictions",
			"invalid_account_data": "Invalid account data",
			"already_exists": "Processor account already exists for the current parameters",
			"not_found": "Processor account not found"
		},
		"free_deposit_limits": {
			"not_test_entity": "Incorrect account type",
			"no_limits_provided": "At least one limit should be provided",
			"invalid_limits": "Limits should satisfy the condition daily <= weekly <= monthly"
		},
		"rule_segments": {
			"no_segment_down": "No segment down",
			"ambiguous_segments": "Ambiguous down segment priority"
		},
		"rule_actions": {
			"invalid_processor_account": "Invalid processor account ID",
			"already_exists": "Such rule action already exists",
			"no_segment_down": "No segment down",
			"ambiguous_segments": "Ambiguous down segment priority"
		},
		"pages": {
			"not_found": "Error: 404 Page not found",
			"access_denied": "Error: 403 Access Denied",
			"something_went_wrong": "Error: 500 Something went wrong"
		},
		"card": {
			"unable_to_unban": "Error when attempting to unban the card",
			"not_banned": "Card is not banned"
		},
		"ab_tests": {
			"not_found": "AB-test not found"
		}
	}
};